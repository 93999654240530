import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { Container, Box, Heading, Text, Link, Flex } from "../components/ui"
import ChevronRight from "../components/chevron-right"
import * as styles from "../components/404.css"

import { Trans } from 'gatsby-plugin-react-i18next';

export default function NotFound(props) {
  return (
    <Layout title="404: Page not found">
      <Box paddingY={4}>
        <Container>
          <Flex variant="column">
            <Heading variant="mega" className={styles.heading}>
              404
            </Heading>
            <Heading as="h1">
              <Trans>Page not found</Trans>
            </Heading>
            <Flex variant="column" gap={0}>
              <Text variant="lead" className={styles.text}>
                <Trans>Sorry! We couldn’t find the page you were looking for.</Trans>
              </Text>
              <Link to="/" className={styles.link}>
                <span>
                  <Trans>Back to home</Trans>
                </span>
                <ChevronRight className={styles.linkChevron} />
              </Link>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
